export const BRANCH_CLEAR = 'BRANCH_CLEAR';
export const BRANCH_FETCH = 'BRANCH_FETCH';
export const BRANCH_SELECTED = 'BRANCH_SELECTED';
export const BRANCH_CREATE = 'BRANCH_CREATE';

export default {
    clear: BRANCH_CLEAR,
    fetch: BRANCH_FETCH,
    selected: BRANCH_SELECTED,
    create: BRANCH_CREATE
};
